input {
    background: get-color(primaryblack);
    border-radius: 5px;
    color: get-color(greyint);
    font-size: get-font-size(subtitle-1);
    padding: 1rem 2.5rem;
}

label {
    margin-bottom: 0.5rem;
}