* {
    padding: 0;
    margin: 0;
}

button,
input {
    border: none;
}

button,
input:focus-visible {
    border: none;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
}