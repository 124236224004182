// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$grapnel-fe-primary: mat.define-palette(mat.$indigo-palette);
$grapnel-fe-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// $grapnel-fe-primary: get-color(primaryblack);
// $grapnel-fe-accent: get-color(accent);
// The warn palette is optional (defaults to red).
$grapnel-fe-warn: mat.define-palette(mat.$red-palette);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// $grapnel-fe-theme: mat.define-light-theme((
//   color: (
//     primary: $grapnel-fe-primary,
//     accent: $grapnel-fe-accent,
//     warn: $grapnel-fe-warn,
//   )
// ));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-component-themes($grapnel-fe-theme);

/* You can add global styles to this file, and also import other style files */

// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "style/main.scss";
@import "theme.scss";

:root {
    color-scheme: dark;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    // overflow: hidden;
}

html,
body {
    height: 100%;
}


/* Change the white to any color */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #2c2c2c inset !important;
    -webkit-box-shadow: 0 0 0 30px #2c2c2c inset !important;
}

.mat-button.mat-primary .mat-button-focus-overlay {
    background-color: transparent;
}

.snackBar {
    background-color: #2c2c2c;
}

.crawled-result-html-contents {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 1.5;
    color: #AAAAAA;
    overflow: auto;
}

.crawled-result-highlighted-text {
    font-family: "Poppins";
    color: #3bd141;
    font-weight: 600;
}

.selected {
    background-color: #d3ac5e;
    color: #0c0c0c;
    padding: 0 2px;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #F18989 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgb(241 137 137 / 26%);
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #F18989 !important;
}

body {
    overflow-x: hidden !important;
}

.clean-html-wrapper table {
  border-collapse: collapse;
}

.clean-html-wrapper table, th, td {
  border: 1px solid;
}

.clean-html-wrapper th, td {
  padding: 4px 6px;
}

.clean-html-wrapper {
  h1 {
    font-size: 48px;
    margin-top: 10px;
  }

  h2 {
    font-size: 36px;
    margin-top: 10px;
  }

  h3 {
    font-size: 30px;
    margin-top: 10px;
  }

  h4 {
    font-size: 24px;
    margin-top: 10px;
  }

  h5 {
    font-size: 20px;
    margin-top: 10px;
  }

  h6  {
    font-size: 18px;
    margin-top: 10px;
  }
}
